.searchTextBox {
  min-width: 50px;
  max-width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: var(--card-box-shadow);
  border-radius: 25px;
  cursor: pointer;
  &:hover {
    box-shadow: var(--card-box-shadow-hover);
  }
  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      box-shadow: var(--card-box-shadow);
    }
  }
  &__icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  &__text-box {
    input {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 19px;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      padding: 2px 20px 0 0;
    }
  }
}
