.m-emp-table {
  margin-top: 22px;
  &__header {
    display: flex;
    align-items: center;
    gap: 14px;
    flex-wrap: wrap;
    &__filter-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--card-box-shadow);
      border-radius: 25px;
      cursor: pointer;
      &:hover {
        box-shadow: var(--card-box-shadow-hover);
      }
    }
    &__filter-text {
      font-family: "TTCommons-Regular";
      margin: 4px 0 0 0;
      font-size: 18px;
      line-height: 19px;
      color: var(--primary-dark-color);
      height: 19px;
    }
    &__filter-clear-all {
      font-family: "TTCommons-Regular";
      margin: 4px 0 0 0;
      font-size: 18px;
      line-height: 19px;
      color: var(--primary-dark-color);
      height: 19px;
      cursor: pointer;
      border-bottom: 1px solid var(--primary-dark-color);
    }
  }
  &__filters {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: var(--card-box-shadow);
    margin: 20px 64px 0 64px;
    @media (max-width: 500px) {
      margin: 20px 0 0 0;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 14px 0;
      h4 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 0 0;
      }
    }
    &__body {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      position: relative;
      @media (max-width: 500px) {
        flex-direction: column;
        flex-wrap: nowrap;
      }
      &__filter {
        flex-grow: 1;
        max-width: 180px;
        @media (max-width: 500px) {
          max-width: 100%;
        }
        > div {
          > div {
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }
  &__body {
    margin-top: 30px;
  }
  &--widget {
    margin-top: 0;
    table {
      min-width: 880px;
      thead {
        tr {
          th {
            font-size: 15px !important;
            line-height: 17px !important;
            font-family: "TTCommons-Regular" !important;
            padding: 0px 15px 15px !important;
            font-weight: 400 !important;
            &:first-child {
              padding: 0px 15px 15px 30px !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 15px !important;
            line-height: 17px !important;
            font-family: "TTCommons-Regular" !important;
            padding: 10px 16px 14px !important;
            font-weight: 400 !important;
            height: 64px;
            &:first-child {
              padding: 10px 16px 14px 30px !important;
            }
          }
        }
      }
    }
  }
}
